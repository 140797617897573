import { gql } from '@apollo/client';
import {QuotePayload} from "../mutation/quote";

export const GET_QUOTES = gql`
  query getAllQuotes($text: String, $offset: Int, $limit: Int) {
    quotes(text: $text, limit: $limit, offset: $offset) {
      quotes ${QuotePayload}
      hasMore
      total
    }
  }
`;

export const GET_QUOTE_DETAILS = gql`
  query getQuote($quoteId: String) {
    quote(quoteId: $quoteId) ${QuotePayload}
  }
`;