import { gql } from "@apollo/client";

export const GET_ALL_PRESENTATIONS = gql`
  query getAllPresentations(
    $input: PresentationsInput
  ) {
    getAllPresentations(
        input: $input
    ) {
      _id
      name
      thumb
      pptUrl
      pdfUrl
    }
  }
`;

export const GET_PRESENTATION_BY_ID = gql`
    query getPresentationById(
        $id: String!
    ) {
        getPresentationById(
            id: $id
        ) {
        _id
        name
        thumb
        pptUrl
        pdfUrl
        }
    }
`