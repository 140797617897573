import React, {useContext, createContext} from 'react';

export function CreateAppContext(
  reducer,
  initialState
) {
  const defaultDispatch = p => initialState;
  const stateCtx = React.createContext(initialState);
  const dispatchCtx = React.createContext(defaultDispatch);

  function useStateCtx(property) {
    const state = React.useContext(stateCtx);
    return state[property]; // only one depth selector for comparison
  }

  function useDispatchCtx() {
    return React.useContext(dispatchCtx);
  }

  function Provider(props) {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    return (
      <dispatchCtx.Provider value={dispatch}>
        <stateCtx.Provider value={state}>{props.children}</stateCtx.Provider>
      </dispatchCtx.Provider>
    );
  }

  return [useStateCtx, useDispatchCtx, Provider, stateCtx];
}

// const [useTextState, useTextDispatch, TextProvider] = useCreateContext(initialState, reducer);
// export const TextContext = ctx;
// export function App() {
//   return (
//     <TextProvider>
//       <Component />
//     </TextProvider>
//   )
// }
// export function Component() {
//   const state = useTextState('state')
//   const dispatch = useTextDispatch()
// const increment = useCallback(() => dispatch({ type: 'increment' }), [dispatch]);
//   return (
//     <div>
//       {state}
//       <button onClick={increment}>Toggle</button>
//     </div>
//   )
// }
