import React, {Fragment, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';

// Common Header Components
import MainMenu from './partials/main-menu';
import CartMenu from './partials/cart-menu';
import LoginModal from '../features/modal/login-modal';
import {AuthContext} from '../../contexts/auth/auth.context';

import {filterGeneral, showModal, toggleCategoryFilter} from '../../redux/actions';
import {useAppState} from "../../contexts/app/app.provider";
import {getCartCount, getWishlistCount} from "../../services";
import CartMenuSidebar from "./partials/cart-menu-sidebar";
import WishlistMenuSidebar from "./partials/wishlist-menu-sidebar";

function Header(props) {

  const companyName = useAppState('companyName');
  const pct = useAppState('pct');
  const appCategories = useAppState('categories');
  const socialLinks = useAppState('socialLinks');
  const careerLink = (socialLinks || []).find(sl => sl.name === "career");
  let showAbout = false;

  const [searchVisible, toggleSearchBarVisibility] = useState(false);
  const [cartVisible, toggleCartVisibility] = useState(false);
  const [wishlistVisible, toggleWishlistVisibility] = useState(false);

  if (pct && pct.storeFrontSettings && pct.storeFrontSettings.subDomain && ["testing", "anuraj"].includes(pct.storeFrontSettings.subDomain)) {
    showAbout = true;
  }

  let history = useHistory();
  const [path, setPath] = useState("");
  const {
    authState: {isAuthenticated, user},
    authDispatch,
  } = React.useContext(AuthContext);

  useEffect(() => {
    setPath(window.location.pathname);
  });

  function onLogout(e) {
    e && e.preventDefault();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    authDispatch({type: 'SIGN_OUT'});
    history.push('/');
  }

  function openLoginModal(e) {
    props.showModal('login');
    e.preventDefault();
  }

  const isUserLoggedIn = !!isAuthenticated && !!user;
  const {wishlist, logo = "assets/images/logo.png", container} = props;

  return (
    <>

      <header id="navbar-spy" className="header header-1 header-transparent">
        <nav id="primary-menu" className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <Link className="navbar-brand" style={{display: 'flex', minWidth: 0}} to="/">
              <img className="logo logo-light" src={`/assets/images/logo/logo.png`} alt={companyName}
              />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent"
                    aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarContent">
              <ul className="navbar-nav mr-auto">

                {/*<li>*/}
                {/*  <Link to="/">Home</Link>*/}
                {/*</li>*/}

                <li className={`has-dropdown ${path.indexOf("products") > -1 ? 'active' : ''}`}>
                  <Link
                    to={`${process.env.PUBLIC_URL}/products`}
                    data-toggle="dropdown"
                    className="dropdown-toggle link-hover"
                    data-hover="shop"
                    onClick={e => {
                      e && e.preventDefault();
                      history.push(`${process.env.PUBLIC_URL}/products`);
                    }}
                  >
                    Products
                  </Link>
                  <ul style = {{width: 'fit-content'}} className="dropdown-menu m-cascader">
                    <div >
                      <div id="cascader-manu">
                        <div>
                          <div className='root'>
                            <a>Home Decor</a>
                            <div>
                              <div onClick={() => {
                                props.filterGeneral({category: {value: 'candle-holders', label: 'Candle Holders'}})
                                history.push(`${process.env.PUBLIC_URL}/products`)
                              }}>
                                <a>Candle Holders</a>
                              </div>
                              <div onClick={() => {
                                props.filterGeneral({category: {value: 'lanterns', label: 'Lanterns'}})
                                history.push(`${process.env.PUBLIC_URL}/products`)
                              }}>
                                <a>Lanterns</a>
                              </div>
                              <div onClick={() => {
                                props.filterGeneral({category: {value: 'hurricanes', label: 'Hurricanes'}})
                                history.push(`${process.env.PUBLIC_URL}/products`)
                              }}>
                                <a>Hurricanes</a>
                              </div>
                              <div onClick={() => {
                                props.filterGeneral({category: {value: 'votives', label: 'Votives'}})
                                history.push(`${process.env.PUBLIC_URL}/products`)
                              }}>
                                <a>Votives</a>
                              </div>
                              <div onClick={() => {
                                props.filterGeneral({category: {value: 'wax-votives', label: 'Wax Votives'}})
                                history.push(`${process.env.PUBLIC_URL}/products`)
                              }}>
                                <a>Wax Votives</a>
                              </div>
                              <div onClick={() => {
                                props.filterGeneral({category: {value: 'misc-and-seasonal', label: 'Misc And Seasonal'}})
                                history.push(`${process.env.PUBLIC_URL}/products`)
                              }}>
                                <a>Misc And Seasonal</a>
                              </div>
                            </div>  
                          </div>
                          <div className='root'>
                          <a>Garden</a>
                              <div>
                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'plant-stands-with-planters', label: 'Plant Stands with Planters' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Plant Stands with Planters</a>
                              </div>

                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'promotional-planters', label: 'Promotional Planters' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Promotional Planters</a>
                              </div>

                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'flower-pots-and-vases', label: 'Flower Pots & Vases' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Flower Pots & Vases</a>
                              </div>

                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'bird-houses-green-houses', label: 'Bird Houses, Green Houses' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Bird Houses, Green Houses</a>
                              </div>

                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'candle-sticks-cones', label: 'Candle Sticks, Cones' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Candle Sticks, Cones</a>
                              </div>

                              </div>  
                          </div>
                          <div className='root'>
                            <a>Tableware/Serveware/Barware</a>
                              <div>
                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'plates', label: 'Plates' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Plates</a>
                              </div>

                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'bowls', label: 'Bowls' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Bowls</a>
                              </div>

                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'trays-platters', label: 'Trays, Platters' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Trays, Platters</a>
                              </div>

                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'trays-coasters', label: 'Trays, Coasters' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Trays, Coasters</a>
                              </div>

                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'teapot', label: 'Teapot' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Teapot</a>
                              </div>

                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'chopping-boards', label: 'Chopping Boards' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Chopping Boards</a>
                              </div>

                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'cake-stands', label: 'Cake Stands' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Cake Stands</a>
                              </div>

                              </div>  
                          </div>
                          <div className='root'>
                            <a>Accent Furniture</a>
                              <div>
                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'stools', label: 'Stools' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Stools</a>
                              </div>

                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'side-tables', label: 'Side Tables' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Side Tables</a>
                              </div>

                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'coffee-tables', label: 'Coffee Tables' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Coffee Tables</a>
                              </div>

                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'shelves-racks', label: 'Shelves/Racks' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Shelves/Racks</a>
                              </div>

                              <div onClick={() => {
                                  props.filterGeneral({ category: { value: 'baskets', label: 'Baskets' } });
                                  history.push(`${process.env.PUBLIC_URL}/products`);
                              }}>
                                  <a>Baskets</a>
                              </div>

                              </div>  
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </li>

                {/*<li>*/}
                {/*  <Link to="/collections">Collections</Link>*/}
                {/*</li>*/}

                {/*<li>*/}
                {/*  <Link to="/showrooms">Showrooms</Link>*/}
                {/*</li>*/}

                {/*<li>*/}
                {/*  <Link to="/quotations">Quotations</Link>*/}
                {/*</li>*/}

                <li className={`${path.indexOf("presentations") > -1 ? 'active' : ''}`}>
                  <Link to="/presentations">Presentations</Link>
                </li>

                <li className={`${path.indexOf("exhibitions") > -1 ? 'active' : ''}`}>
                  <Link to="exhibitions">Exhibitions</Link>
                </li>

                <li className={`has-dropdown ${path.indexOf("showrooms") > -1 ? 'active' : ''}`}>
                  <Link to="/showrooms">Showrooms</Link>
                </li>

                {/* <li className={`has-dropdown ${path.indexOf("career") > -1 ? 'active' : ''}`}>
                  <Link to="https://docs.google.com/forms/d/e/1FAIpQLScJCU-LI3bR_T6OY7_r44m-bCycgfY_cdKg_rZ-5BImOnSScA/viewform">Career</Link>
                </li> */}

                {/* <li className={`has-dropdown ${path.indexOf("collections") > -1 ? 'active' : ''}`}>
                  <Link to="/collections">Collections</Link>
                </li> */}
                
                {
                  isUserLoggedIn && <li className={`has-dropdown ${path.indexOf("quotations") > -1 ? 'active' : ''}`}>
                    <Link to="/quotations">Quotations</Link>
                  </li>
                }
                <li className={`has-dropdown ${path.indexOf("products") > -1 ? 'active' : ''}`}>
                  <Link
                    to={`${process.env.PUBLIC_URL}/about`}
                    data-toggle="dropdown"
                    className="dropdown-toggle link-hover"
                    data-hover="shop"
                    onClick={e => {
                      e && e.preventDefault();
                      history.push(`${process.env.PUBLIC_URL}/about`);
                    }}
                  >
                    About
                  </Link>
                  <ul style = {{width: 'fit-content'}} className="dropdown-menu m-cascader">
                    <div >
                      <div id="cascader-manu">
                        <div>
                          <div
                          onClick={e => {
                            e && e.preventDefault();
                            history.push(`${process.env.PUBLIC_URL}/csr`);
                          }}
                          className='root'>
                            <a href={`${process.env.PUBLIC_URL}/csr`}>CSR</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div >
                      <div id="cascader-manu">
                        <div>
                          <div
                          onClick={e => {
                            e && e.preventDefault();
                            history.push(`${process.env.PUBLIC_URL}/sustainability`);
                          }}
                          className='root'>
                            <a href={`${process.env.PUBLIC_URL}/sustainability`}>Sustainability</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </li>     
                {
                  <li className={`has-dropdown ${path.indexOf("career") > -1 ? 'active' : ''}`}>
                    <a href='https://docs.google.com/forms/d/e/1FAIpQLScJCU-LI3bR_T6OY7_r44m-bCycgfY_cdKg_rZ-5BImOnSScA/viewform' target="_blank">Careers</a>
                  </li>
                }
                <li className={`has-dropdown ${path.indexOf("contact") > -1 ? 'active' : ''}`}>
                  <Link to="/contact">Contact</Link>
                </li>

              </ul>
              <div className="module-container d-flex flex-row">

                {/*<div className={`module module-search pull-left ${searchVisible ? 'module-active' : ''}`}>*/}
                {/*  <div className="module-icon search-icon" onClick={() => toggleSearchBarVisibility(!searchVisible)}>*/}
                {/*    <i className="lnr lnr-magnifier"></i>*/}
                {/*    <span className="title">Search</span>*/}
                {/*  </div>*/}
                {/*  <div className="module-content module--search-box">*/}
                {/*    <form className="form-search">*/}
                {/*      <input type="text" className="form-control" placeholder="Search anything"/>*/}
                {/*      <button type="submit"><span className="fa fa-arrow-right"></span></button>*/}
                {/*    </form>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<div className="vertical-divider pull-left mr-30"></div>*/}
                {/*<div className={`module module-cart pull-left ${cartVisible ? 'module-active' : ''}`}>*/}
                {/*  <div className="module-icon cart-icon" onClick={() => toggleCartVisibility(!cartVisible)}>*/}
                {/*    <i className="icon-bag"></i>*/}
                {/*    <span className="title">shop cart</span>*/}
                {/*    <label className="module-label">{getCartCount(props.cartlist)}</label>*/}
                {/*  </div>*/}
                {/*  <CartMenu*/}
                {/*    cartVisible={cartVisible}*/}
                {/*    toggleCartVisibility={toggleCartVisibility}*/}
                {/*  />*/}
                {/*</div>*/}

                <div className={`module module-cart module-cart-3 pull-left ${cartVisible ? 'module-active' : ''}`}>
                  <div
                    className="module-icon cart-icon"
                    onClick={() => {
                      toggleCartVisibility(!cartVisible);
                    }}
                  >
                    <i className="icon-bag"></i>
                    <span className="title">shop cart</span>
                    <label className="module-label">{getCartCount(props.cartlist)}</label>
                  </div>
                  <CartMenuSidebar
                    cartVisible={cartVisible}
                    toggleCartVisibility={toggleCartVisibility}
                  />
                </div>
                <div className={`module module-cart module-cart-3 pull-left ${wishlistVisible ? 'module-active' : ''}`}>
                  <div
                    className="module-icon cart-icon"
                    onClick={() => {
                      toggleWishlistVisibility(!wishlistVisible);
                    }}
                  >
                    <i className="ti-heart"></i>
                    <span className="title">wishlist</span>
                    <label className="module-label">{getWishlistCount(props.wishlist)}</label>
                  </div>
                  <WishlistMenuSidebar
                    visible={wishlistVisible}
                    toggle={toggleWishlistVisibility}
                  />
                </div>

              </div>
            </div>

          </div>

        </nav>
      </header>

      {/*<header className="header header-7">*/}
      {/*  <div className="header-top">*/}
      {/*    <div className={container}>*/}
      {/*      <div className="header-left">*/}
      {/*        /!*<div className="header-dropdown">*!/*/}
      {/*        /!*    <Link to="#">Usd</Link>*!/*/}
      {/*        /!*    <div className="header-menu">*!/*/}
      {/*        /!*        <ul>*!/*/}
      {/*        /!*            <li><Link to="#">Eur</Link></li>*!/*/}
      {/*        /!*            <li><Link to="#">Usd</Link></li>*!/*/}
      {/*        /!*        </ul>*!/*/}
      {/*        /!*    </div>*!/*/}
      {/*        /!*</div>*!/*/}
      {/*        /!*<div className="header-dropdown">*!/*/}
      {/*        /!*    <Link to="#">Eng</Link>*!/*/}
      {/*        /!*    <div className="header-menu">*!/*/}
      {/*        /!*        <ul>*!/*/}
      {/*        /!*            <li><Link to="#">English</Link></li>*!/*/}
      {/*        /!*            <li><Link to="#">French</Link></li>*!/*/}
      {/*        /!*            <li><Link to="#">Spanish</Link></li>*!/*/}
      {/*        /!*        </ul>*!/*/}
      {/*        /!*    </div>*!/*/}
      {/*        /!*</div>*!/*/}
      {/*      </div>*/}
      {/*      <div className="header-right">*/}
      {/*        <ul className="top-menu header-dropdown">*/}
      {/*          <li>*/}
      {/*            <Link to="#">Links</Link>*/}
      {/*            <ul>*/}
      {/*              {*/}
      {/*                pct && pct.phone ? <li>*/}
      {/*                  <a href={`tel:${pct.phone}`}>*/}
      {/*                    <i className="icon-phone"></i>Call: {pct.phone}*/}
      {/*                  </a>*/}
      {/*                </li> : null*/}
      {/*              }*/}
      {/*              <li>*/}
      {/*                <Link to={`${process.env.PUBLIC_URL}/wishlist`}>*/}
      {/*                  <i className="icon-heart-o"></i>My Wishlist <span>({wishlist.length})</span>*/}
      {/*                </Link>*/}
      {/*              </li>*/}
      {/*              {showAbout && <li><Link to={`${process.env.PUBLIC_URL}/about`}>About Us</Link></li>}*/}
      {/*              /!*<li><Link to={ `${process.env.PUBLIC_URL}/pages/contact` }>Contact Us</Link></li>*!/*/}
      {/*              {*/}
      {/*                !isUserLoggedIn ? <li>*/}
      {/*                  <Link*/}
      {/*                    to={`${process.env.PUBLIC_URL}/login`}*/}
      {/*                    data-toggle="modal"*/}
      {/*                    // onClick={openLoginModal}*/}
      {/*                  >*/}
      {/*                    <i className="icon-user"></i>Login*/}
      {/*                  </Link>*/}
      {/*                </li> : <Fragment>*/}
      {/*                  <li>*/}
      {/*                    <Link to={`/profile`}>*/}
      {/*                      <i className="icon-user"></i> {`${user.firstName} ${user.lastName}`}*/}
      {/*                    </Link>*/}
      {/*                  </li>*/}
      {/*                  <li>*/}
      {/*                    <Link to={`#logout`} onClick={onLogout}>*/}
      {/*                      Logout*/}
      {/*                    </Link>*/}
      {/*                  </li>*/}
      {/*                </Fragment>*/}
      {/*              }*/}
      {/*            </ul>*/}
      {/*          </li>*/}
      {/*        </ul>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="header-middle sticky-header">*/}
      {/*    <div className={container}>*/}
      {/*      <div className="header-left">*/}
      {/*        <button className="mobile-menu-toggler">*/}
      {/*          <span className="sr-only">Toggle mobile menu</span>*/}
      {/*          <i className="icon-bars"></i>*/}
      {/*        </button>*/}

      {/*        <Link to={`${process.env.PUBLIC_URL}/`} className="logo">*/}
      {/*          {*/}
      {/*            pct && pct.logo ? <img*/}
      {/*              // src={"https://app.poly9.ai/public/5d9dd1be2c482a1dbcaabf22/logo/3f19a9a9-f3af-4776-878b-4cd00c65c14b.jpeg"}*/}
      {/*              src={`${process.env.CLOUD_FRONT_URL}${pct.logo}`}*/}
      {/*              alt={companyName}*/}
      {/*              style={{maxHeight: '50px'}}*/}
      {/*            /> : null*/}
      {/*          }*/}
      {/*          /!*<img src={ process.env.PUBLIC_URL + '/' + logo } alt={companyName} width="105" height="25" />*!/*/}
      {/*        </Link>*/}

      {/*        <MainMenu/>*/}
      {/*      </div>*/}
      {/*      <div className="header-right">*/}
      {/*        /!*{*!/*/}
      {/*        /!*  path !== '/' ? <div className="header-search header-search-extended header-search-visible">*!/*/}
      {/*        /!*    <Link to="#" className="search-toggle" role="button"><i className="icon-search"></i></Link>*!/*/}
      {/*        /!*    /!*<form>*!/*!/*/}
      {/*        /!*      <div className="header-search-wrapper search-wrapper-wide">*!/*/}
      {/*        /!*        <label htmlFor="q" className="sr-only">Search</label>*!/*/}
      {/*        /!*        <input type="search" className="form-control" name="q" id="q" placeholder="Search product ..."*!/*/}
      {/*        /!*               required/>*!/*/}
      {/*        /!*        <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>*!/*/}
      {/*        /!*      </div>*!/*/}
      {/*        /!*    /!*</form>*!/*!/*/}
      {/*        /!*  </div> : ''*!/*/}
      {/*        /!*}*!/*/}

      {/*        <CartMenu/>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <LoginModal/>*/}

      {/*</header>*/}
    </>
  );
}

function mapStateToProps(state) {
  return {
    wishlist: state.wishlist.list ? state.wishlist.list : [],
    cartlist: state.cartlist.cart ? state.cartlist.cart : []
  }
}

export default connect(mapStateToProps, {showModal, toggleCategoryFilter, filterGeneral})(Header);