import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

import {addToCart, toggleWishlist, addToCompare, showQuickViewModal, filterSort} from '../../../redux/actions';
import SearchBox from "../../../components/search-box-custom";
import { exhibitions as exhibitionsList } from '../constants';

function Exhibitions(props) {
  // let products = props.products, timer;
  let timer;

  const [exhibitions] = useState(exhibitionsList);

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    }
  }, [])


  return (
    <>

      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 category-options">
          <div className="category-num pull-left pull-none-xs">
            <h2><span>4</span>EXHIBITIONS FOUND</h2>
            {/*Showing <span>{products.length} of {totalCount}</span> Products*/}
          </div>

          <div className="category-select pull-right text-right text-left-sm pull-none-xs">
            <ul className="list-unstyled mb-0">
              <li className="option sort--options mr-20 pr-1">
                <SearchBox
                  placeholder={"Search exhibitions ..."}
                  // onChangeText={(text) => setSearchText(text)}
                />
              </li>
              {/*<li className="option sort--options">*/}
              {/*  <span className="option--title">Sort by:</span>*/}
              {/*  <div className="select-form">*/}
              {/*    <i className="fa fa-caret-down"></i>*/}
              {/*    <select*/}
              {/*      name="sortby" id="sortby" defaultValue={filters.sortBy} className="form-control"*/}
              {/*      onChange={changeFilter}*/}
              {/*    >*/}
              {/*      <option value="latest">Newest Arrival</option>*/}
              {/*    </select>*/}
              {/*  </div>*/}
              {/*</li>*/}

            </ul>
          </div>
        </div>
      </div>

      <div style = {{display: 'flex', alignItems: 'start', justifyContent: 'start', flexWrap: 'wrap', gap: '40px'}}>
        {exhibitions.map((item, index) => (
          <a href = {item.url} target='_blank' key = {index} style = {{width: '30%'}}>
            <div>
              <img src = {item.image} style = {{width: '100%'}} />
              <h6 style = {{textAlign: 'center', marginTop: '10px'}}>{item.name}</h6>
            </div>
          </a>
        ))}
      </div>

     </> 
  );
}

export const mapStateToProps = (state) => {
  return {
    products: state.data.products ? state.data.products : [],
    filters: state.filters
  }
}

export default connect(
  mapStateToProps, {
    addToCart, toggleWishlist, addToCompare, showQuickViewModal, filterSort
  }
)(Exhibitions);