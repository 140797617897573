import React from 'react';
import {safeContent} from '../../utils';
import {Link} from 'react-router-dom';
import moment from "moment";

function Comment(props) {
  const {image, name, date, content, entry} = props;

  let myMessage = (props.chatHelper.getUUID() === entry.message.uuid);

  if (entry.message.user_type === "visitor" && entry.message.content_type === "email") {
    return <></>
  }

  // Visitor cannot see sys messages
  if (entry.message.user_type === "sys") {
    return <></>
  }

  if (!(entry.message.content_type === "text")) {
    console.log("format not supported")
    return <></>
  }

  const formatMessageTime = (timetoken) => {
    return moment(new Date(parseInt(timetoken) / 10000)).format('MMM DD, yyyy [at] hh:mm a');
  };

  return (
    <>

      {/*<div className="author--img">*/}
      {/*  <img src="assets/images/testimonials/authors/3.jpg" alt="author">*/}
      {/*</div>*/}

      {/*<div className="review--comment-content">*/}
      <div className="">
        <div className="clearfix">
          <div className={myMessage ? "pull-right" : "pull-left"}>
            <h6 className={`${myMessage ? "mr-0" : ""}`}>
              {entry.message.user_name} <span className="review--date">{formatMessageTime(entry.timetoken)}</span>
            </h6>
          </div>
        </div>

        <div className={`product--comment ${myMessage ? "text-right" : "text-left"}`}>
          <p dangerouslySetInnerHTML={safeContent(entry.message.text)}></p>
        </div>
      </div>
    </>
  );
}

export default React.memo(Comment);