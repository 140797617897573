import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {addToCart, toggleWishlist, addToCompare, showQuickViewModal, filterSort} from '../../../redux/actions';
import { useQuery } from '@apollo/client';
import {  GET_PRESENTATION_BY_ID } from '../../../graphql/query/presentations.query';
// import { Document } from 'react-pdf'

function Presentations(props) {
  // let products = props.products, timer;
  let timer;
    console.log(window.location.href)

  const {data: presentationData} = useQuery(GET_PRESENTATION_BY_ID, {
    variables: {
        id: window.location.href.split('/').pop()
    }
  })

  const presentation = presentationData ? presentationData.getPresentationById : {};
  console.log(presentation)
  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    }
  }, [])


  return (
    <>
      <div style = {{display: 'flex', alignItems: 'start', justifyContent: 'start', flexWrap: 'wrap', gap: '40px'}}>
        {/* <Document file={presentation.pdfUrl} /> */}
      </div>
     </> 
  );
}

export const mapStateToProps = (state) => {
  return {
    products: state.data.products ? state.data.products : [],
    filters: state.filters
  }
}

export default connect(
  mapStateToProps, {
    addToCart, toggleWishlist, addToCompare, showQuickViewModal, filterSort
  }
)(Presentations);