import React, {useEffect, useState} from 'react';

function SearchBox(props) {

  const [inputText, setInputText] = useState("");
  const [search, setSearchText] = useState("");

  useEffect(() => {
    props.onChangeText && props.onChangeText(search);
  }, [search])

  return <>
    {/*<div*/}
    {/*  className={`module module-search module-search-left pull-left module-active`}>*/}
    {/*  <div className="module-content module--search-box">*/}
    <div className="form-search d-flex">
      <input
        type="text"
        className="form-control mb-0"
        placeholder={props.placeholder || "Search ..."}
        onChange={e => {
          setInputText(e.currentTarget.value);
          if (e.currentTarget.value === "") {
            setSearchText("");
          }
        }}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            setSearchText(inputText);
          }
        }}
      />
      <button type="button" className="cursor-pointer" onClick={e => setSearchText(inputText)}>
        <span className="fa fa-search"></span>
      </button>
    </div>
    {/*</div>*/}
    {/*</div>*/}
    {
      false && <div className="d-flex" style={{
        width: '270px',
        borderRadius: '0',
        border: 'none',
        borderBottom: '.1rem solid #ccc'
      }}>
        <label htmlFor="q" className="sr-only">Search</label>
        <input
          style={{
            paddingLeft: '1rem',
            paddingBottom: '1rem',
            fontWeight: '300',
            flex: '1 1 auto',
            "width": "100%",
            "margin": "0",
            "height": "44px",
            "lineHeight": "20px",
            "border": "none",
            "boxShadow": "none",
            "paddingTop": "1.2rem",
            "paddingRight": "2.4rem",
            "fontSize": "1.4rem",
            "letterSpacing": "0",
            "backgroundColor": "#fff"
          }}
          type="search"
          className="form-control"
          name="q"
          id="q"
          placeholder={props.placeholder || "Search ..."}
          required
          onChange={e => {
            setInputText(e.currentTarget.value);
            if (e.currentTarget.value === "") {
              setSearchText("");
            }
          }}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              setSearchText(inputText);
            }
          }}
        />
        <button
          style={{
            "flex": "0 0 40px",
            "maxWidth": "40px",
            "height": "46px",
            "fontSize": "1.8rem",
            "minWidth": "0",
            "fontWeight": "400",
            "lineHeight": "1",
            "border": "0",
            "padding": "0",
            "margin": "0",
            "cursor": "pointer",
            "marginTop": "-1px",
            "marginRight": "-1px",
            "marginBottom": "-1rem"
          }}
          className="btn btn-primary"
          type="submit"
          onClick={e => {
            setSearchText(inputText);
          }}
        >
          <i className="icon-search"></i>
        </button>
      </div>
    }
  </>

}

export default SearchBox;