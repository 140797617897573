import React, {useEffect} from 'react';
import {useAppState} from "../../contexts/app/app.provider";

function Footer(props) {

  const pct = useAppState('pct');
  const companyName = useAppState('companyName');
  const socialLinks = useAppState('socialLinks');
  const {container = "container"} = props;

  const socialNameIconMapper = {
    facebook: "facebook",
    twitter: "twitter",
    pinterest: "pinterest-p",
    instagram: "instagram",
    linkedinS: "linkedin-square",
    youtube: "youtube-play",
    whatsapp: "whatsapp",
  }


  useEffect(() => {
    var $ = window.jQuery;
    var siteContent = $("#wrapperParallax");
    var contentParallax = $(".contentParallax");
    var siteFooter = $("#footerParallax");
    var siteFooterHeight = siteFooter.height();
    siteContent.css({
      "margin-bottom": siteFooterHeight
    });
  }, []);

  const socialLinksStatic = [
    {name: 'facebook', url: 'https://www.facebook.com/paramounthomecollections/'},
    {name: 'twitter', url: 'https://x.com/ParamountHome_C'},
    {name: 'youtube', url: 'https://www.youtube.com/paramounthome'},
    {name: 'pinterest', url: 'https://in.pinterest.com/paramounthomecollections/'},
    {name: 'linkedinS', url: 'https://www.linkedin.com/uas/login?session_redirect=%2Fcompany%2F2325994%2F'},
    {name: 'instagram', url: 'https://www.instagram.com/paramount_home_collections/'},
    {name: 'whatsapp', url: 'https://api.whatsapp.com/send/?phone=%2B919897682000&text&type=phone_number&app_absent=0'},

  ]

  return (
    <>
      <footer id="footerParallax" className="footer footer-2">
        <div className="container pr-40 pl-40">
          <div style = {{marginBottom: '20px'}} className="footer-widget">
            <div className="row">
              {/* <div className="col-sm-12 col-md-12 col-lg-4  widget--logo text-center-xs">
                <div className="widget--content">
                  <div className=" widget--logo-img align-items-center d-flex flex-row justify-content-center flex-wrap">
                    <img
                      src={`${process.env.CLOUD_FRONT_URL}${pct.logo}`}
                      alt={companyName}
                      style={{
                        height: '60px', marginRight: '15px', display: 'block',
                        maxWidth: '270px',
                        objectFit: 'contain'
                      }}
                    /> <h6 className={'footer-company-name'}>{companyName}</h6>
                  </div>
                </div>

              </div>

              <div className="col-sm-12 col-md-6 col-lg-4  text-center-xs widget--contact-info">
                <div className="widget--content">
                  <ul className="list-unstyled">
                    <li></li>
                    {
                      pct.email && <li><a href={`mailto:${pct.email}`}>{pct.email}</a></li>
                    }
                    {
                      pct.phone && <li><a href={`tel:${pct.phone}`}>{pct.phone}</a></li>
                    }
                  </ul>
                </div>
              </div> */}

              {/*<div className="col-sm-12 col-md-6 col-lg-3  text-center-xs widget--links">*/}
              {/*  <div className="widget--content">*/}
              {/*    <ul className="list-unstyled">*/}
              {/*      <li><a href="page-about-1.html">About Us </a></li>*/}
              {/*      <li><a href="page-privacy.html">Privacy Policy</a></li>*/}
              {/*      <li><a href="page-terms.html">Term & Conditions</a></li>*/}
              {/*      <li><a href="#">Help</a></li>*/}
              {/*    </ul>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="col-sm-12 col-md-6 col-lg-4   text-center-xs widget--social">
                <div className="widget--content">
                  <img src = '/assets/images/logo/logo.png' width={'50px'} alt = 'logo'/> 
                  <p style = {{fontWeight: 'bold', color: 'black'}}>PARAMOUNT HOME COLLECTIONS</p>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4   text-center-xs widget--social">
                <div className="widget--content">
                  <p style = {{fontWeight: 'bold', color: 'black', marginBottom: '0'}}>Contact</p>
                  <a href='mailto:arbab@paramounthomecollections.com'><p style = {{marginBottom: '0'}}>arbab@paramounthomecollections.com</p></a>
                  <a href='tel:+919897682000'><p style = {{marginBottom: '0'}}>+91 98976 82000</p></a>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4   text-center-xs widget--social">
                <div className="widget--content">
                  <div className="social--icons">
                    <p style = {{fontWeight: 'bold', color: 'black'}}>Follow Us On Social</p>
                    {
                      socialLinksStatic && socialLinksStatic.length ?
                        socialLinksStatic.map((it, index) => {

                          if(!socialNameIconMapper[it.name]){
                            return null;
                          }

                          return <a key={index} className={it.name} href={it.url} target="_blank">
                            <i className={`fa fa-${socialNameIconMapper[it.name]}`}></i>
                          </a>
                        })
                        : ''
                    }
                  </div>
                </div>
              </div>

            </div>

            <div style={{textAlign: 'center'}} className="footer--copyright">
              <span>
                Copyright © {(new Date()).getFullYear()} Paramount Home Collections. All Rights Reserved.
              </span>
            </div>

          </div>

        </div>

      </footer>

      {
        false && <footer className="footer footer-2" ref={props.footerEle}>
          {/*<div className="footer-middle">*/}
          {/*    <div className={ container }>*/}
          {/*        <div className="row">*/}
          {/*            <div className="col-sm-12 col-lg-4">*/}
          {/*                <div className="widget widget-about">*/}
          {/*                    <img src={ `${process.env.PUBLIC_URL}/assets/images/logo-footer.png` } className="footer-logo" alt="Footer Logo" width="105" height="25" />*/}
          {/*                    <p>Praesent dapibus, neque id cursus ucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus. </p>*/}

          {/*                    <div className="widget-about-info">*/}
          {/*                        <div className="row">*/}
          {/*                            <div className="col-sm-6 col-md-4">*/}
          {/*                                <span className="widget-about-title">Got Question? Call us 24/7</span>*/}
          {/*                                <Link to="tel:123456789">+0123 456 789</Link>*/}
          {/*                            </div>*/}
          {/*                            <div className="col-sm-6 col-md-8">*/}
          {/*                                <span className="widget-about-title">Payment Method</span>*/}
          {/*                                <figure className="footer-payments">*/}
          {/*                                    <img src={ `${process.env.PUBLIC_URL}/assets/images/payments.png` } alt="Payment methods" width="272" height="20" />*/}
          {/*                                </figure>*/}
          {/*                            </div>*/}
          {/*                        </div>*/}
          {/*                    </div>*/}
          {/*                </div>*/}
          {/*            </div>*/}

          {/*            <div className="col-sm-4 col-lg-2">*/}
          {/*                <div className="widget">*/}
          {/*                    <h4 className="widget-title">Useful Links</h4>*/}

          {/*                    <ul className="widget-list">*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/about` }>About {companyName}</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/pages/about` }>How to shop on {companyName}</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/pages/faq` }>FAQ</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/pages/contact` }>Contatc us</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/pages/login` }>Log in</Link></li>*/}
          {/*                    </ul>*/}
          {/*                </div>*/}
          {/*            </div>*/}

          {/*            <div className="col-sm-4 col-lg-2">*/}
          {/*                <div className="widget">*/}
          {/*                    <h4 className="widget-title">Customer Service</h4>*/}

          {/*                    <ul className="widget-list">*/}
          {/*                        <li><Link to="#">Payment Methods</Link></li>*/}
          {/*                        <li><Link to="#">Money-back guarantee!</Link></li>*/}
          {/*                        <li><Link to="#">Returns</Link></li>*/}
          {/*                        <li><Link to="#">Shipping</Link></li>*/}
          {/*                        <li><Link to="#">Terms and conditions</Link></li>*/}
          {/*                        <li><Link to="#">Privacy Policy</Link></li>*/}
          {/*                    </ul>*/}
          {/*                </div>*/}
          {/*            </div>*/}

          {/*            <div className="col-sm-4 col-lg-2">*/}
          {/*                <div className="widget">*/}
          {/*                    <h4 className="widget-title">My Account</h4>*/}

          {/*                    <ul className="widget-list">*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/pages/login` }>Log in</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/cart` }>View Cart</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/shop/wishlist` }>My Wishlist</Link></li>*/}
          {/*                        <li><Link to="#">Track My Order</Link></li>*/}
          {/*                        <li><Link to="#">Help</Link></li>*/}
          {/*                    </ul>*/}
          {/*                </div>*/}
          {/*            </div>*/}

          {/*            <div className="col-sm-6 col-lg-2">*/}
          {/*                <div className="widget widget-newsletter">*/}
          {/*                    <h4 className="widget-title">Sign Up To Newsletter</h4>*/}

          {/*                    <p>Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan.</p>*/}

          {/*                    <form action="#">*/}
          {/*                        <div className="input-group">*/}
          {/*                            <input type="email" className="form-control" placeholder="Enter your Email Address" aria-label="Email Adress" required />*/}
          {/*                            <div className="input-group-append">*/}
          {/*                                <button className="btn btn-dark" type="submit"><i className="icon-long-arrow-right"></i></button>*/}
          {/*                            </div>*/}
          {/*                        </div>*/}
          {/*                    </form>*/}
          {/*                </div>*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</div>*/}

          <div className="footer-bottom">
            <div className={container}>
              <p className="footer-copyright">Copyright © {(new Date()).getFullYear()} Paramount Home Collections. All Rights Reserved.</p>
              {/*<ul className="footer-menu">*/}
              {/*    <li><Link to="#">Terms Of Use</Link></li>*/}
              {/*    <li><Link to="#">Privacy Policy</Link></li>*/}
              {/*</ul>*/}

              {/*<div className="social-icons social-icons-color">*/}
              {/*    <span className="social-label">Social Media</span>*/}
              {/*    <a href="https://www.facebook.com" className="social-icon social-facebook" rel="noopener noreferrer" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>*/}
              {/*    <a href="https://twitter.com" className="social-icon social-twitter" rel="noopener noreferrer" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>*/}
              {/*    <a href="https://instagram.com" className="social-icon social-instagram" rel="noopener noreferrer" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>*/}
              {/*    <a href="https://youtube.com" className="social-icon social-youtube" rel="noopener noreferrer" title="Youtube" target="_blank"><i className="icon-youtube"></i></a>*/}
              {/*    <a href="https://pinterest.com" className="social-icon social-pinterest" rel="noopener noreferrer" title="Pinterest" target="_blank"><i className="icon-pinterest"></i></a>*/}
              {/*</div>*/}
            </div>
          </div>
        </footer>
      }
    </>
  );
}

export default React.memo(Footer);