export const exhibitions = [
    {
        name: "IHGF Delhi Fair February 2024",
        url: "/request-form?id=3",
        image: "/assets/images/exhibitions/exhibitions-1.jpg"

    },
    {
        name: "2024 Ambiente Frankfurt",
        url: "https://my.matterport.com/show/?m=cWF412gVHVg",
        image: "/assets/images/exhibitions/exhibitions-2.jpg"
    },
    {
        name: "IHGF Delhi Fair October 2023",
        url: "/request-form?id=1",
        image: "/assets/images/exhibitions/exhibitions-3.jpg"
    },
    {
        name: "IHGF Delhi Fair March 2023",
        url: "/request-form?id=2",
        image: "/assets/images/exhibitions/exhibitions-4.jpg"
    },
]