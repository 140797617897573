import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
// import Custom Components
import PageHeader from '../../../components/common/page-header';
import Breadcrumb from "../../../components/common/breadcrumb";
import Layout from '../../../components/app';
import {useAppState} from "../../../contexts/app/app.provider";
import SinglePresentation from './single';

function NoSideBar(props) {
  const type = "fullwidth";
  const title = {"boxed": "Showrooms", "fullwidth": "Showrooms"}
  const companyName = useAppState('companyName');

  useEffect(() => {
    if (type !== "boxed" && type !== "fullwidth") {
      window.location = process.env.PUBLIC_URL + "/pages/404";
    }
  }, [type])

  function hideSideBar() {
    if (document.querySelector('body').classList.contains('sidebar-filter-active'))
      document.querySelector('body').classList.remove('sidebar-filter-active');
  }

  return (
    <Layout>
      <Helmet>
        Presentation - {companyName}
      </Helmet>

      <h1 className="d-none">Presentation - {companyName}</h1>

      <PageHeader title="Presentations" subTitle="" displayCategory={false}/>

      <Breadcrumb
        title={'Presentation'}
        adClass="mb-2"
        container={type === "boxed" ? "container" : "container-fluid"}
      />

      <section id="category" className="category category-1 pt-5">
        <div className="container">
          <SinglePresentation type={type}/>
        </div>
      </section>

    </Layout>
  )
}

export default React.memo(NoSideBar);