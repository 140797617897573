import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {removeFromWishlist, addToCartFromWishlist} from '../../../redux/actions';
import {useAppState} from "../../../contexts/app/app.provider";

function WishlistMenuSidebar(props) {

  const pct = useAppState('pct');
  const addToCartBtnText = useAppState('addToCartBtnText');
  const currencySymbol = useAppState('currencySymbol');
  const showPrice = pct && pct.productSettings && pct.productSettings.showPrice;
  const showQuantity = pct && pct.productSettings && pct.productSettings.showQuantity;

  const {wishlist} = props;

  return (
    <>
      <div className="module-content module-hamburger cart-box">
        <Link
          className="module-cancel"
          to="#"
          onClick={() => {
            props.toggle(!props.visible);
          }}
        >
          CLOSE
        </Link>
        <div className="cart-title">
          <h5>Wishlist</h5>
        </div>

        <div className="cart-overview">
          {
            0 === wishlist.length ? <p>No products in the wishlist.</p> : <>
              <ul className="list-unstyled">
                {
                  wishlist.map((item, index) => {
                    return <li key={index}>
                      <img className="img-fluid" src={`${process.env.CLOUD_FRONT_URL}${item.thumb}`}
                           alt={item.name}/>
                      <div className="product-meta">
                        <h5 className="product-title text-truncate mr-4">{item.name}</h5>
                        {
                          showQuantity && <p className="product-qunt">Quantity: {item.qty}</p>
                        }
                        {
                          showPrice && <p className="product-price">
                            {currencySymbol}
                            {(item.priceValue || 0).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}
                          </p>
                        }
                        <div className="d-flex flex-row pt-3">
                          <a
                            href={"#"}
                            className="px-2"
                            onClick={(e) => {
                              e.preventDefault()
                              props.addToCartFromWishlist(item, (showQuantity ? 1 : 0))
                            }}
                          >
                            {addToCartBtnText}
                          </a>
                          {/*<a*/}
                          {/*  href={"#"}*/}
                          {/*  className="text-danger px-2"*/}
                          {/*  onClick={(e) => {*/}
                          {/*    e.preventDefault()*/}
                          {/*    props.removeFromWishlist(item.productId)*/}
                          {/*  }}*/}
                          {/*>*/}
                          {/*  remove*/}
                          {/*</a>*/}
                        </div>
                      </div>
                      <Link
                        className="cart-cancel"
                        to="#"
                        onClick={e => {
                          e && e.preventDefault();
                          props.removeFromWishlist(item.productId)
                        }}
                      >
                        <i className="lnr lnr-cross"></i>
                      </Link>
                    </li>
                  })
                }
                {/*<li>*/}
                {/*  <img className="img-fluid" src="assets/images/products/thumb/1.jpg"*/}
                {/*       alt="product"/>*/}
                {/*  <div className="product-meta">*/}
                {/*    <h5 className="product-title">Hebes Great Chair</h5>*/}
                {/*    <p className="product-qunt">Quantity: 01</p>*/}
                {/*    <p className="product-price">$24.00</p>*/}
                {/*  </div>*/}
                {/*  <Link className="cart-cancel" to="#"><i className="lnr lnr-cross"></i></Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <img className="img-fluid" src="assets/images/products/thumb/2.jpg"*/}
                {/*       alt="product"/>*/}
                {/*  <div className="product-meta">*/}
                {/*    <h5 className="product-title">Hebes Great Chair</h5>*/}
                {/*    <p className="product-qunt">Quantity: 01</p>*/}
                {/*    <p className="product-price">$24.00</p>*/}
                {/*  </div>*/}
                {/*  <Link className="cart-cancel" to="#"><i className="lnr lnr-cross"></i></Link>*/}
                {/*</li>*/}
              </ul>
            </>
          }
        </div>

      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    wishlist: state.wishlist.list
  }
}

export default connect(mapStateToProps, {
  removeFromWishlist,
  addToCartFromWishlist
})(WishlistMenuSidebar);