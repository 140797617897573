import jwt from "jsonwebtoken";
import {CookieHandler} from "../../utils/CookieHelper";

const isBrowser = typeof window !== 'undefined';
export const initialState = () => {
  let pct = undefined;
  try {
    pct = isBrowser && jwt.decode(localStorage.getItem("pct"));
  } catch (e) {

  }
  return {
    searchTerm: '',
    isSticky: false,
    isSidebarSticky: true,
    isDrawerOpen: false,
    isModalOpen: false,
    appAuthenticated: !!pct,
    pct,
    companyName: pct ? pct.name : "",
    currencySymbol: ((pct && pct.currency && pct.currency.symbol) || ""),
    categories: ((pct && pct.categories) || []),
    materialCategories: ((pct && pct.materialCategories) || []),
    banners: ((pct && pct.banners) || []),
    socialLinks: ((pct && pct.socialLinks) || []),
    addToCartBtnText: ((pct && pct.productSettings && pct.productSettings.addToCartBtnText) || "Add To Cart"),
  }
};

export function appReducer(state, action) {
  switch (action.type) {
    case 'SET_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload,
      };
    case 'SET_STICKY':
      return {
        ...state,
        isSticky: true,
      };
    case 'REMOVE_STICKY':
      return {
        ...state,
        isSticky: false,
      };
    case 'SET_SIDEBAR_STICKY':
      return {
        ...state,
        isSidebarSticky: true,
      };
    case 'REMOVE_SIDEBAR_STICKY':
      return {
        ...state,
        isSidebarSticky: false,
      };
    case 'TOGGLE_DRAWER':
      return {
        ...state,
        isDrawerOpen: !state.isDrawerOpen,
      };
    case 'TOGGLE_MODAL':
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
      };
    case 'SET_PCT':
      localStorage.setItem('pct', action.payload);
      let decoded = isBrowser && !!action.payload ? jwt.decode(action.payload) : undefined;
      let guestLogin = CookieHandler.getCookieValue('guestLogin');

      if (!guestLogin && !!(decoded.customerLoginPreferences && decoded.customerLoginPreferences.loginRequired)) {
        CookieHandler.setCookie('guestLogin', true, 365);
      }

      return {
        ...state,
        appAuthenticated: !!decoded,
        pct: decoded,
        companyName: decoded && decoded.name || "",
        currencySymbol: decoded.currency && decoded.currency.symbol || "",
        categories: decoded.categories || [],
        materialCategories: decoded.materialCategories || [],
        banners: decoded.banners || [],
        socialLinks: ((decoded.socialLinks) || []),
        addToCartBtnText: ((decoded.productSettings && decoded.productSettings.addToCartBtnText) || "Add To Cart"),
      };
    default: {
      throw new Error(`Unsupported action type at App Reducer`);
    }
  }
}
