import React, {Fragment} from "react";
import {connect} from "react-redux";
import Modal from "react-modal";
import {hideAddToCartModal} from "../../redux/actions/index";
import {Link, useHistory} from "react-router-dom";
import {safeContent} from "../../utils";
import {useAppState} from "../../contexts/app/app.provider";
import {IS_MOBILE_DEVICE} from "../../constants/defaultValues";

let modalCloseTimer;
Modal.setAppElement('#root');

const customStyles = {
  content: {
    ...(IS_MOBILE_DEVICE ? {} : {
      top: '50%',
      transform: 'translateY(-50%)',
      position: 'absolute'
    })
  },
  overlay: {
    backgroundColor: 'rgba(51,51,51,0.6)',
    zIndex: '10000'
  }
};

function AddToCartModal(props) {
  const history = useHistory();
  const pct = useAppState('pct');
  const currencySymbol = useAppState('currencySymbol');
  const showPrice = pct && pct.productSettings && pct.productSettings.showPrice;

  const {showModal, hideAddToCartModal, cartModalData} = props;

  let item = cartModalData;

  return <Modal
    isOpen={showModal}
    contentLabel="AddToCart"
    onAfterOpen={() => {
      modalCloseTimer = setTimeout(() => {
        hideAddToCartModal()
      }, 5000)
    }}
    onRequestClose={() => {
      if (modalCloseTimer) {
        clearTimeout(modalCloseTimer);
      }
    }}
    shouldFocusAfterRender={false}
    style={customStyles}
    className="container w-100" id="add-to-card-modal"
    overlayClassName={
      "ReactModal__Overlay add-to-cart-overlay"
    }
  >
    {
      item ?
        <div className="modal-content cart-dropdown m-auto p-0" style={{
          maxWidth: IS_MOBILE_DEVICE ? '100%' : '270px'
        }}>
          <div className="modal-body p-4 w-100">
            <h6 className={'text-primary'}>Added to cart</h6>
            <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => {
              hideAddToCartModal()
            }}>
              <span>×</span></button>
            <>
              <div className="dropdown-cart-products">
                <div className="product px-0">
                  <div className="product-cart-details">
                    <h4 className="product-title">
                      <Link
                        to={`${process.env.PUBLIC_URL}/products/${item.productId}`}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        dangerouslySetInnerHTML={safeContent(item.name)}/>
                    </h4>

                    <span className="cart-product-info">
                        <span className="cart-product-qty">{item.qty}</span>
                      {
                        showPrice && item.priceValue ? <Fragment>
                          x {currencySymbol}
                          {item.priceValue && item.priceValue.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                        </Fragment> : ''
                      }
                      </span>
                  </div>

                  <figure className="product-image-container">
                    <Link
                      to={`${process.env.PUBLIC_URL}/products/${item.productId}`}
                      className="product-image"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <img src={`${process.env.CLOUD_FRONT_URL}${item.thumb}`}
                           data-oi={`${process.env.CLOUD_FRONT_URL}${item.thumb}`} alt={item.name}/>
                    </Link>
                  </figure>
                  {/*<button className="btn-remove" title="Remove Product"*/}
                  {/*        onClick={() => removeFromCart(item.productId)}>*/}
                  {/*  <i className="icon-close"></i>*/}
                  {/*</button>*/}
                </div>
              </div>
              {
                showPrice ? <div className="dropdown-cart-total">
                  <span>Total</span>

                  <span className="cart-total-price">{currencySymbol}{item.sum.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}</span>
                </div> : ''
              }

              <div className="dropdown-cart-action">
                <Link
                  to={`${process.env.PUBLIC_URL}/cart`}
                  className="btn btn-primary btn-block"
                  onClick={(e) => {
                    e.preventDefault();
                    hideAddToCartModal()
                    history.push(`${process.env.PUBLIC_URL}/cart`);
                  }}
                >View Cart</Link>
              </div>
            </>
          </div>
        </div>
        : ''
    }
  </Modal>;
}


const mapStateToProps = ({cartlist}) => {
  return {
    showModal: cartlist.showModal,
    cartModalData: cartlist.cartModalData
  };
};

export default connect(mapStateToProps, {hideAddToCartModal})(AddToCartModal)