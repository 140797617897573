import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {ToastContainer} from 'react-toastify';
import {withRouter} from 'react-router-dom';
import store from '../redux/store';
// import Custom Components
import Header from './common/header';
import Footer from './common/footer';
import MobileMenu from './common/mobile-menu';
// import Utils
import {mobileMenu, preventProductDefault, removePreventProductDefault, stickyHeaderHandler} from '../utils';
// import Actions
import {closeQuickViewModal} from '../redux/actions';
import AddToCartModal from './AddToCartModal';

// import data

function App(props) {

  useEffect(() => {
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/61406b5425797d7a89fedeae/1ffhq6gt3';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
  }, [])

  return (
    <>

      <div id="wrapperParallax" className="wrapper clearfix">
        <Header />
        {
          props.children
        }
        <Footer/>
      </div>

      {/*<div className="page-wrapper">*/}
      {/*  <Header container={container} urls={prevPath}/>*/}
      {/*  <div className={'main-container'} ref={mainContainer}>*/}
      {/*    {*/}
      {/*      props.children*/}
      {/*    }*/}
      {/*  </div>*/}

      {/*  <Footer container={container} footerEle={footerEle}/>*/}

      {/*  <ToastContainer autoClose={3000} className="toast-container"/>*/}

      {/*  <AddToCartModal/>*/}
      {/*</div>*/}

      {/*<MobileMenu/>*/}

    </>
  );
}

export default withRouter(App);

